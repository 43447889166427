import React from "react";

export default function PaymentReceipt(props) {
    let cardIcons = [];
  cardIcons['V']='visa.svg';
  cardIcons['M']='mastercard.svg';
  cardIcons['AX']='amex.svg';

    return (
        <div>
            {props.paymentReceipt.cc.order_no && (
              <>
              <div className="mb-3">
              <label className="form-label">
                  Payment Confirmation Number
                </label>
                <div>{props.paymentReceipt.cc.order_no}</div>
                </div>

              <div className="mb-3">
              <label className="form-label">
                  Transaction Date
                </label>
                <div>{props.paymentReceipt.cc.transaction_date_time}</div>
                </div>
              <div className="mb-3">
              <label className="form-label">
                  Credit Card
                </label>
                <div className="d-flex justify-content-center flex-row">
                <div className="me-2">{props.paymentReceipt.cc.card_type && (
                        <img
                          src={`/img/${
                            cardIcons[props.paymentReceipt.cc.card_type]
                              ? cardIcons[props.paymentReceipt.cc.card_type]
                              : "credit-card-regular.svg"
                          }`}
                          alt="credit card"
                          height="20px"
                        />
                      )}
                      </div>
                      <div>
                      {props.paymentReceipt.cc.first6last4 && (
                      <div>
                        **** **** ****{" "}
                        {props.paymentReceipt.cc.first6last4.substr(
                          props.paymentReceipt.cc.first6last4.length - 4
                        )}
                      </div>
                    )}
                      </div>
                      </div>
                </div>
              <div className="mb-3">
              <label className="form-label">
                  Amount
                </label>
                <div>${Number(props.paymentReceipt.cc.amount).toFixed(2)}</div>
                </div>
              </>
            )}
        </div>
    )
}