import React, { useState, forwardRef, useEffect, useRef } from "react";
import FileUpload from "./FileUpload";
import { useForm, Controller } from "react-hook-form";
import Receipt from "./Receipt";
import FOIRequest from "./FOIRequest";
import Payment from "./Payment";
import DatePicker from "react-datepicker";
import Spinner from "./Spinner";
import "react-datepicker/dist/react-datepicker.css";
import '../datepicker.css';
import { FileStatus } from "filepond";
import apiHeaders from "../apiHeaders";

export default function Form() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
  } = useForm();

  // the server generates new ids
  const setNewId = async () => {
    let newIdResponse = await fetch(`${process.env.REACT_APP_API_URL || ''}/api/get-new-id`,{headers:apiHeaders});
    let newId;
    if(newIdResponse.ok){
      newId = await newIdResponse.text();
      setValue('requestId', newId);
    }else{
      throw Error("Cannot get new id from server");
    }
  }

  const DateCustomInput = forwardRef((props, ref) => (
    <>
      <input className={'form-control' + (props.error ? ' is-invalid' : '')} defaultValue={props.value} onClick={props.onClick} id={props.fieldName} autoComplete="off" ></input>
      <div className="invalid-feedback">{props.error}</div>
    </>
  ));

  const ResetButton = ({label}) => (
  <button className="btn no-print d-print-none" type="button" onClick={resetForm}>
    <div>
      <i className="fas fa-redo" style={{ fontSize: "2.5rem" }}></i>
    </div>
    <div>{label || 'Reset Form'}</div>
  </button>
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [payment, setPayment] = useState(null);
  const [fileUploadBusy, setFileUploadBusy] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const formErrorRef = useRef(null);
  let formValues = watch();
  let sameLastName = watch('infoRequestLastNameCheckbox');
  let description = watch("description");

  const submit = async (paymentReceipt) => {
    let data = formValues;
    
    if(data.authorizationLetter && data.authorizationLetter.length === 1){
      data.authorizationLetter = data.authorizationLetter.map(file => ({
        filename: file.filename,
        fileExtension: file.fileExtension,
        filenameWithoutExtension: file.filenameWithoutExtension,
        fileSize: file.fileSize,
        serverId: file.serverId,
      }));
    }

    if(data.descriptionDoc && data.descriptionDoc.length === 1){
      data.descriptionDoc = data.descriptionDoc.map(file => ({
        filename: file.filename,
        fileExtension: file.fileExtension,
        filenameWithoutExtension: file.filenameWithoutExtension,
        fileSize: file.fileSize,
        serverId: file.serverId,
      }));
    }

    if(data.infoRequestLastNameCheckbox || data.infoRequestLastName){
      data.ownRecords = true;
    }

    paymentReceipt.cc.first6last4 = paymentReceipt.cc.first6last4.substr(paymentReceipt.cc.first6last4.length - 4);
    data.fromDate = data.fromDate ? new Intl.DateTimeFormat('en-CA').format(data.fromDate) : null;
    data.toDate = data.toDate ? new Intl.DateTimeFormat('en-CA').format(data.toDate) : null;

    if(data.fromDate || data.toDate) data.dateRange = true;

    try {
      const submitResponse = await fetch(`${process.env.REACT_APP_API_URL || ''}/api/submit`, {
        method: 'POST', headers: {
          'Content-Type': 'application/json',
          ...apiHeaders
        }, body: JSON.stringify({ paymentReceipt, ...data })
      });
      if(!submitResponse.ok) setSubmitError(true);
    } catch (error) {
      setSubmitError(true);
    }
  }

  const resetForm = () => {
    reset();
    setNewId();
    clearErrors();
    setReceipt(null);
    setSubmitError(false);
    scrollToTop();
    window.sessionStorage.clear();
  };

  const getStoredValues = () => {
    const str = window.sessionStorage.getItem('form');
    if (str) {
      const values = JSON.parse(str)

      Object.keys(values).forEach(key => {
        if(values[key]){
          if(key === 'fromDate' || key === 'toDate') setValue(key, new Date(values[key]));
          // else if(key === 'authorizationLetter') debugger;
          else setValue(key, values[key], { shouldValidate: false, shouldDirty: false });
        }
      });
    }
  };

  useEffect(() => {
    getStoredValues();
    if(!getValues('requestId')){
      setNewId();
    }
  }, []);

  useEffect(() => {
    if(submitError) scrollToTop();
  }, [submitError])

  const storeValues = () => {
    window.sessionStorage.setItem(
      "form",
      JSON.stringify({
        ...formValues,
        authorizationLetter:formValues['authorizationLetter'] ? formValues['authorizationLetter'].filter(fileItem => fileItem.status != FileStatus.LOAD_ERROR).map(fileItem => ({source: fileItem.serverId, fileName: fileItem.file ? fileItem.file.name : fileItem.fileName ,options: {type: 'limbo', file: {name: fileItem.file ? fileItem.file.name : fileItem.options.file.name, type: fileItem.file ? fileItem.file.type : fileItem.options.file.type, size: fileItem.file ? fileItem.file.size : fileItem.options.file.size}}})) : null,
        descriptionDoc:formValues['descriptionDoc'] ? formValues['descriptionDoc'].filter(fileItem => fileItem.status != FileStatus.LOAD_ERROR).map(fileItem => ({source: fileItem.serverId, fileName: fileItem.file ? fileItem.file.name : fileItem.fileName ,options: {type: 'limbo', file: {name: fileItem.file ? fileItem.file.name : fileItem.options.file.name, type: fileItem.file ? fileItem.file.type : fileItem.options.file.type, size: fileItem.file ? fileItem.file.size : fileItem.options.file.size}}})) : null
      })
    );
  };
  useEffect(storeValues, [formValues]);

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // PAYMENT FUNCTIONS
  const startPayment = async (data) => {
    setSubmitError(false);
    setPayment(getValues('requestId'));
    scrollToTop();
  }

  const onPaymentApproved = (receipt) => {
    submit(receipt);
    setPayment(null);
    setReceipt({paymentReceipt: receipt, ...getValues()});
    if(!submitError) {
      reset();
    }
    setNewId();
    scrollToTop();
  }
  
  const onPaymentDeclined = (receipt) => {
    scrollToTop();
  }

  const onPaymentCancelled = () => {
    getStoredValues();
    setPayment(null);
  }

  return (
    <div>
      {submitError && (
        <div className="alert alert-danger" role="alert">
          An unknown error has occurred with your submission. Please contact <a href="mailto:access.privacy@york.ca">access.privacy@york.ca</a> for assistance.
        </div>
      )}
      {isSaving && <Spinner />}
      {!receipt && !payment && !isSaving && (
        <div style={{maxWidth:'800px', margin:'auto'}}>
          <p>A $5.00 online fee payment will be required to submit a request (on next page).</p>
          <form className="text-start">
          <input {...register("requestId")} type="hidden" name="requestId" />

            {/* REQUESTER INFORMATION */}
            <label htmlFor="requesterLastName" className="form-label h3 fs-5">Requester Information</label>

            <div className="mb-3">
              <label htmlFor="requesterFirstName" className="form-label">
                First Name
                <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterFirstName", { required: "First name is required" })} type="text" id="requesterFirstName" className={`form-control ${errors.requesterFirstName && "is-invalid"}`} aria-label="First name"/>
              {errors.requesterFirstName && (
                  <div className="invalid-feedback">{errors.requesterFirstName.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="requesterLastName" className="form-label">
                Last Name
                <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterLastName",{ required: "Last name is required" })} type="text" id="requesterLastName" className={`form-control ${errors.requesterLastName && "is-invalid"}`} aria-label="Last name"/>
              {errors.requesterLastName && (
                  <div className="invalid-feedback">{errors.requesterLastName.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="requesterCompanyName" className="form-label">
                Company Name (if appropriate)
              </label>
              <input {...register("requesterCompanyName")} type="text" id="requesterCompanyName" className="form-control" aria-label="Company name"/>
            </div>
            <div className="mb-3">
              <label htmlFor="requesterAddress" className="form-label">
              Address (Street/Apt No. P.O. Box No/R.R. No.)
              <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterAddress", { required: "Address is required" })} type="text" id="requesterAddress" className={`form-control ${errors.requesterAddress && "is-invalid"}`} aria-label="Address"/>
              {errors.requesterAddress && (
                  <div className="invalid-feedback">{errors.requesterAddress.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="requesterMunicipality" className="form-label">
              City or Town
              <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterMunicipality", { required: "City or town is required" })} type="text" id="requesterMunicipality" className={`form-control ${errors.requesterMunicipality && "is-invalid"}`} aria-label="City or Town"/>
              {errors.requesterMunicipality && (
                  <div className="invalid-feedback">{errors.requesterMunicipality.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="requesterCountry" className="form-label">
              Country
              <span className="required-astrisk">*</span>
              </label>
                <select {...register("requesterCountry", { required: "Country is required" })} name="requesterCountry" id="requesterCountry" className={`form-select ${errors.requesterCountry && "is-invalid"}`} defaultValue={'Canada'}>
                  <option>Canada</option>
                  <option>Other</option>
                </select>
                {errors.requesterCountry && (
                  <div className="invalid-feedback">{errors.requesterCountry.message}</div>
                )}
            </div>
            { getValues('requesterCountry') === 'Canada' ? 
              <div className="mb-3">
                <label htmlFor="requesterProvince" className="form-label">
                Province
                <span className="required-astrisk">*</span>
                </label>
                  <select {...register("requesterProvince", { required: "Province is required" })} name="requesterProvince" id="requesterProvince" className={`form-select ${errors.requesterProvince && "is-invalid"}`} defaultValue={'Ontario'}>
                    <option>Alberta</option>
                    <option>British Columbia</option>
                    <option>Manitoba</option>
                    <option>New Brunswick</option>
                    <option>Newfoundland and Labrador</option>
                    <option>Nova Scotia</option>
                    <option>Northwest Territories</option>
                    <option>Nunavut</option>
                    <option value="Ontario">Ontario</option>
                    <option>Prince Edward Island</option>
                    <option>Quebec</option>
                    <option>Saskatchewan</option>
                    <option>Yukon</option>
                  </select>
                  {errors.requesterProvince && (
                    <div className="invalid-feedback">{errors.requesterProvince.message}</div>
                  )}
              </div> : 
              <div className="mb-3">
                <label htmlFor="requesterProvince" className="form-label">
                Province / State
                <span className="required-astrisk">*</span>
                </label>
                <input {...register("requesterProvince", { required: "Province / State is required" })} type="text" id="requesterProvince" className={`form-control ${errors.requesterProvince && "is-invalid"}`} aria-label="Province / State"/>
                {errors.requesterProvince && (
                  <div className="invalid-feedback">{errors.requesterProvince.message}</div>
                )}
              </div>
            }
            <div className="mb-3">
              <label htmlFor="requesterPostalCode" className="form-label">
              {getValues('requesterCountry') === 'Canada' ? 'Postal Code' : 'Postal / ZIP Code'}
              <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterPostalCode", { required: "Postal code is required" })} type="text" id="requesterPostalCode" className={`form-control ${errors.requesterPostalCode && "is-invalid"}`} aria-label="Postal Code"/>
              {errors.requesterPostalCode && (
                  <div className="invalid-feedback">{errors.requesterPostalCode.message}</div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="requesterPhone" className="form-label">
              Phone Number
              <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterPhone", { required: "Phone number is required" })} 
              type="text" id="requesterPhone" className={`form-control ${errors.requesterPhone && "is-invalid"}`} aria-label="Phone Number"/>
            </div>
            <div className="mb-3">
              <label htmlFor="requesterEmail" className="form-label">
              Email
              <span className="required-astrisk">*</span>
              </label>
              <input {...register("requesterEmail", { required: "A valid email is required to send receipt of request", pattern: {value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "A valid email is required to send receipt of request" }})} type="text" id="requesterEmail" className={`form-control ${errors.requesterEmail && "is-invalid"}`} aria-label="Email"/>
              {errors.requesterEmail && (
                  <div className="invalid-feedback">{errors.requesterEmail.message}</div>
                )}
            </div>
            
            <div className="mb-3">
              <label htmlFor="personalInfoRequestLastName" className="form-label">
              If this request is for access to, or correction of, own personal information records, the last name appearing on records is:
              </label>

              <div className="form-check">
                <input {...register("infoRequestLastNameCheckbox")} className={"form-check-input" + (errors.infoRequestLastNameCheckbox ? " is-invalid" : "")} type="checkbox" value="" id="infoRequestLastNameCheckbox"/>
                <label className="form-check-label" htmlFor="infoRequestLastNameCheckbox">
                  Same as above
                </label>
              </div>
       
              <label htmlFor="infoRequestLastName" className="form-label">or Different Last Name</label>
              <input {...register("infoRequestLastName")} type="text" id="infoRequestLastName" className={"form-control" + (errors.infoRequestLastNameCheckbox ? " is-invalid" : "")} aria-label="Last name" disabled={sameLastName}/>
              {errors.infoRequestLastNameCheckbox && (
                  <div className="invalid-feedback">{errors.infoRequestLastNameCheckbox.message}</div>
                )}
            </div>

            {/* DESCRIPTION OF RECORDS OR CORRECTION REQUESTED */}
            <label htmlFor="description" className="form-label h3 fs-5">
              Description of Records or Correction Requested
              <span className="required-astrisk">*</span>
            </label>
            <div className="mb-3">
              <textarea {...register("description", {
                  validate: (value) => { 
                    let theFiles = Array.isArray(getValues("descriptionDoc")) ? getValues("descriptionDoc").filter(file => file.status !== FileStatus.LOAD_ERROR) : null;
                    return ( (value?.length > 0 || theFiles?.length > 0) ? true : "Please enter a description or upload a document")
                  }
                })} className={"form-control" + (errors.description ? " is-invalid" : "")} id="description" rows="3" maxLength="2000"></textarea>
              <p style={{"color":description && description.length >= 2000 ? "orange" : "inherit"}}>{description && description.length} / 2000 characters allowed</p>
              {errors.description && (
                  <div className="invalid-feedback">{errors.description.message}</div>
                )}
                <div className="col-sm-6">
                <label htmlFor="descriptionDoc" className="form-label">
                  <div>or upload a document describing the request</div>
                  <small>(.pdf, .txt, .docx or image)</small>
                </label>
                
                <FileUpload
                  files={formValues.descriptionDoc}
                  setFiles={(files) => setValue('descriptionDoc', files)}
                  disabled={isSaving}
                  setIdle={() => setFileUploadBusy(false)}
                  setBusy={() => setFileUploadBusy(true)}
                  acceptedFileTypes={process.env.REACT_APP_ACCEPTED_FILE_TYPES?.split(',')}
                />
                <input
                  type="hidden"
                  className={"form-control" + (errors.descriptionDoc ? " is-invalid" : "")}
                  {...register("descriptionDoc")}
                ></input>
                </div>
            </div>

            <label htmlFor="fromDate" className="form-label">
              Time Period for Records
            </label>
            <div className="row">
              <div className="col-sm">
                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label">
                    From Date
                  </label>
                  <Controller
                    control={control}
                    name='fromDate'
                    defaultValue=""
                    render={({ field }) => (
                      <DatePicker
                        placeholderText='Select date'
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        customInput={<DateCustomInput error={errors.fromDate && errors.fromDate.message} fieldName='fromDate'/>}
                        disabled={isSaving}
                        maxDate={new Date()}
                        minDate={new Date(1971,1,1)}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                    />)}
                  />
                </div>
              </div>
              <div className="col-sm">
                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label">
                    To Date
                  </label>
                  <Controller
                    control={control}
                    name='toDate'
                    defaultValue=""
                    render={({ field }) => (
                      <DatePicker
                        placeholderText='Select date'
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        customInput={<DateCustomInput error={errors.toDate && errors.toDate.message} fieldName='toDate'/>}
                        disabled={isSaving}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        maxDate={new Date()}
                        minDate={new Date(getValues('fromDate'))}
                    />)}
                  />
                </div>
              </div>
            </div>

           
            <div className="mb-3">
              <div className="col-sm-6">
              <label htmlFor="authorizationLetter" className="form-label">
                <div>Authorization Letter</div>
                <small>(only required if requesting personal information on behalf of another individual)</small>
                <small>(.pdf, .txt, .docx or image)</small>
              </label>
              
              <FileUpload
                files={formValues.authorizationLetter}
                setFiles={(files) => setValue('authorizationLetter', files)}
                disabled={isSaving}
                setIdle={() => setFileUploadBusy(false)}
                setBusy={() => setFileUploadBusy(true)}
                acceptedFileTypes={process.env.REACT_APP_ACCEPTED_FILE_TYPES?.split(',')}
              />
              <input
                type="hidden"
                className={"form-control" + (errors.authorizationLetter ? " is-invalid" : "")}
                {...register("authorizationLetter")}
              ></input>
              {errors.authorizationLetter && (
                <div className="invalid-feedback">{errors.authorizationLetter.message}</div>
              )}
              </div>
            </div> 
            

            <div className="d-flex justify-content-evenly">
      
            <div>
              <button
                type="button"
                className="btn btn-primary d-print-none"
                onClick={() => handleSubmit(startPayment)()}
                disabled={isSaving || fileUploadBusy}
                style={{position: "relative", top: "50%", transform: "translate(0, -50%)"}}
              >
                {!isSaving && "Next"}
                {isSaving && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Submitting...
                  </>
                )}
              </button>
           </div>

           <ResetButton/>
      
            </div>
          </form>
        </div>
      )}

      {receipt && (
        <div style={{ textAlign: "center" }}>
          <div className="mb-3">
            <Receipt {...receipt} submitError={submitError}/>
          </div>
          <button className="btn btn-primary d-print-none" type="button" onClick={resetForm}>
            <div>
              <i className="fas fa-redo" style={{ fontSize: "2.5rem" }}></i>
            </div>
            <div>New Request</div>
          </button>
        </div>
      )}

      {payment && <div style={{ maxWidth: '1000px', margin: 'auto' }}>
      <div className="mb-3">To submit your request, we require a payment of a $5.00 application fee.</div>
        <h1 style={{ fontSize: "1.3rem" }} className="mb-4">Review Request Details</h1>
        <div className="card mb-3">
          <div className="card-body">
            <FOIRequest {...getValues()} requestId="· · · · · · " />
          </div>
        </div>
        <Payment requestId={payment} {...getValues()} onPaymentApproved={onPaymentApproved} onPaymentDeclined={onPaymentDeclined} onPaymentCancelled={onPaymentCancelled} />
      </div>}
    </div>
  );
}
