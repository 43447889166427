import React, { useState, useEffect, useContext } from "react";
import { MonerisContext } from "./MonerisProvider";
import Spinner from "./Spinner";
import apiHeaders from "../apiHeaders";

export default function Payment(props) {
    const monerisContext = useContext(MonerisContext);
    const [transaction, setTransaction] = useState(null);
    const [displayCheckout, setDisplayCheckout] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentError, setPaymentError] = useState(false);

    useEffect(() => {
        setLoading(true);
        monerisContext.loadMonerisResources().then(preloadTransaction).catch(error => {console.error(error); setLoadingError(true)});
        return monerisContext.cleanupTransaction;
    }, []);

    const preloadTransaction = () => {
        fetch(`${process.env.REACT_APP_API_URL || ''}/api/preload-transaction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...apiHeaders
            },
            body: JSON.stringify({ requestId: props.requestId, 
                requesterFirstName: props.requesterFirstName, 
                requesterLastName: props.requesterLastName, 
                requesterEmail: props.requesterEmail })
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(responseJson => {
            if(responseJson.ticket){
                let monerisCheckout = monerisContext.getMonerisCheckout();
                monerisCheckout.setMode(monerisContext.monerisEnvironment);
                monerisCheckout.setCheckoutDiv("monerisCheckout");
                monerisCheckout.setCallback("error_event",console.error);
                monerisCheckout.setCallback("page_loaded",console.log);
                monerisCheckout.setCallback("cancel_transaction", cancelTransaction);
                monerisCheckout.setCallback("payment_receipt",transactionReceipt);
                monerisCheckout.setCallback("payment_complete",transactionReceipt);
                monerisCheckout.startCheckout(responseJson.ticket);
                monerisCheckout.transactionTicket = responseJson.ticket;
            }else{
                console.error(responseJson);
            }
            setLoading(false);
        })
        .catch((err) => {
            console.error(err);
            setLoadingError(true);
            setLoading(false);
        });
    }

    const transactionReceipt = response => {
        setProcessing(true);
        monerisContext.getMonerisCheckout().closeCheckout(" ");
        monerisContext.transactionTicket = null;
        setDisplayCheckout(false);
        const body = {ticket: JSON.parse(response).ticket, requestId: props.requestId};
        fetch(`${process.env.REACT_APP_API_URL || ''}/api/process-receipt`, {method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            ...apiHeaders
            },
            body: JSON.stringify(body)
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(transactionResponse => {
            setTransaction(transactionResponse);
            if(transactionResponse.result === 'a'){
                // HANDLE SUCCESS
                props.onPaymentApproved(transactionResponse);
            }else{
                setPaymentError(true);
                props.onPaymentDeclined(transactionResponse);
            }
            setProcessing(false);
        }).catch((err) => {
            //TODO handle error here
            console.error(err);
            setPaymentError(true);
            setProcessing(false);
        });
    };

    const cancelTransaction = response => {
        let monerisCheckout = monerisContext.getMonerisCheckout();
        monerisCheckout.closeCheckout(" ");//monerisCheckout.transactionTicket);
        monerisCheckout.transactionTicket = null;
        props.onPaymentCancelled();
    };

    const resetPayment = () => {
        setPaymentError(false);
        setLoadingError(false);
        setProcessing(false);
        setTransaction(null);
        setDisplayCheckout(true);
        preloadTransaction();
    }

    return (
        <div style={{maxWidth:'800px', margin:'auto'}}>
            { processing && <div>
                <Spinner />
                <div className="text-center">processing payment, please wait ...</div>
            </div> }
            { paymentError && <div className="m-4">
                { transaction && <div style={{marginTop:"30px"}}>
                    { transaction.result === 'a' ? <div>
                    <div><img src="/img/green-check.png"  width="100px" alt="Green Checkmark"/></div>
                    <div className="mb-4">Your transaction has been approved</div>
                    </div> : <div>
                        <div><img src="/img/red-x.png"  width="100px" alt="Red X"/></div>
                        <div className="mb-4 text-danger">Your transaction has been declined</div>
                    </div> }
                    <div className="mb-4">Confirmation Number: <strong>{transaction.cc.order_no}</strong></div>
                    <button className="btn btn-primary d-print-none" type="button" onClick={resetPayment}>Retry</button>
                </div> }
                { !transaction && <div>
                    <div>Due to an application error, your transaction may not have been processed at this time. Please contact <a href="mailto:access.privacy@york.ca">access.privacy@york.ca</a> for assistance. Please do not attempt another online transaction at this time.</div>
                </div> }
            </div> }
            {loadingError && <div className="alert alert-danger" role="alert">
                An error occurred while loading the payment gateway. Please contact <a href="mailto:access.privacy@york.ca">access.privacy@york.ca</a> for assistance.
            </div>}
            { !paymentError && !loadingError && 
            <div>
                { loading && <div>
                    <Spinner /><span>loading payment gateway...</span>
                </div> }
                <div className="monerisContainer">
                    {!loading && !processing && <h2 className="fs-6">Payment Gateway by <a href="https://www.moneris.com/" target="_blank">Moneris</a></h2>}
                    { displayCheckout && <div id="monerisCheckout"></div> }
                </div>
            </div> }
        </div>
    );
}