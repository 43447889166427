import Form from "./Form";
import Header from "./Header"
import Footer from "./Footer";

export default function Home(props) {
  return (
    <>
    <Header/>
    <div className="py-3 text-center mx-2">
      {/* <img src="logo--york-region-blue-EN.svg" alt="York Region Logo" className="mb-2"/> */}
      {/* <h1 className="mb-4">Freedom of Information Access / Correction Request</h1> */}
      <Form/>
    </div>
    <Footer/>
    </>
  );
}
