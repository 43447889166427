import React from "react";
import FOIRequest from "./FOIRequest";
import PaymentReceipt from "./PaymentReceipt";

export default function Receipt(props) {
  let cardIcons = [];
  cardIcons['V'] = 'visa.png';
  cardIcons['M'] = 'mastercard.png';
  cardIcons['AX'] = 'amex.png';

  return (
    <div style={{ maxWidth: "1000px", margin: "auto" }}>
      <div className="text-center">
        {props.paymentReceipt.result !== "d" ? (
          <>
            {props.submitError ?
              <div className="text-danger mb-2">Your request payment has been accepted but there was an error submitting.</div> : 
              <>
                <div>
                  <img
                    src="/img/green-check.png"
                    width="100px"
                    alt="Green Checkmark"
                  />
                </div>
                <div className="mb-2">Your request has been submitted</div>
                <div className="mb-2">A copy of your submission has been sent to the email provided</div> 
              </>
            } 
            
            <button className="btn btn-primary d-print-none" onClick={() => { window.print(); return false; }} >Print</button>
          </>
        ) : (
          <>
            <div>
              <img src="/img/red-x.png" width="100px" alt="Red X" />
            </div>
            <div className="mb-4 text-danger">
              Your payment transaction has been declined. Your request has not been submitted.
            </div>
          </>
        )}
      </div>
      <h1 style={{ fontSize: "1.3rem" }} className="my-4">
        Request Details
      </h1>
      <div className="card">
        <div className="card-body">
          <div>
            <FOIRequest {...props} />
          </div>
        </div>
      </div>
      <h1 style={{ fontSize: "1.3rem" }} className="my-4">
        Receipt for payment
      </h1>
      <div className="card">
        <div className="card-body">
          {props.paymentReceipt.result !== "d" && <PaymentReceipt paymentReceipt={props.paymentReceipt} />}
        </div>
      </div>
    </div>
  );
}
