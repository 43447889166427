import React from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import apiHeaders from '../apiHeaders';

registerPlugin(FilePondPluginFileValidateSize,FilePondPluginFileValidateType,FilePondPluginFileRename);

export default function FileUpload(props) {
    const deleteFile = (source) => {
      console.log(source);
    }

    return (
        <FilePond
          files={props.files}
          onupdatefiles={props.setFiles}
          allowMultiple={false}
          server={{
            process: `${process.env.REACT_APP_API_URL || ''}/api/upload`,
            headers: (file, metadata) => ({
              'Upload-Length': file.file ? file.file.size : file.size,
              ...apiHeaders
            }),
            remove: (source, load, error) => {
              if(window.confirm('Are you sure you want to delete this file?')) deleteFile(source);
            },
          }}
          name="files"
          labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
          credits={false}
          required={props.required}
          disabled={props.disabled}
          onprocessfiles = { props.setIdle }
          onaddfilestart = { props.setBusy}
          onerror = { props.setIdle }
          maxFileSize = '10MB'
          acceptedFileTypes={props.acceptedFileTypes || ['application/pdf']}
          fileRenameFunction= { (file) => {
            return file.name.replaceAll(/[\*\\\/\:\<\>\|\?\"]/gm,'');
          }}
        />
    );
}