import React, {useState, createRef, useEffect} from 'react';
import apiHeaders from '../apiHeaders';

export const MonerisContext = React.createContext({});

export const MonerisProvider = ({ children }) => {
    const [partialPaymentAmount, setPartialPaymentAmount] = useState(null); // stores on client with '$' sign and decimal, remember to strip to number before sending to server
    const [isResourceLoaded, setIsResourceLoaded] = useState(false);
    const [monerisEnvironment, setMonerisEnvironment] = useState(null);

    let monerisRef = createRef();

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL || ''}/api/moneris-environment`, { method: 'GET', headers: apiHeaders}).then(response => {
        if(response.ok) return response.text();
      }).then(text => {
        setMonerisEnvironment(text);
      });
    }, []);

    /**
   * Moneris actions are at top level due to an issue with placing it at lower level component.
   * The closeCheckout command is not working properly during component cleanup and that means you will end up with multiple instances of Moneris checkout which will call the callbacks multiple times as well.
   */
    const loadMonerisResources = () => {
      return new Promise((resolve, reject) => {
        if(!isResourceLoaded && monerisEnvironment){
          const script = document.createElement("script");
          let scriptSrc;
          if(monerisEnvironment === 'qa'){
            scriptSrc = 'https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js';
          }else if(monerisEnvironment === 'prod'){
            scriptSrc = 'https://gateway.moneris.com/chkt/js/chkt_v1.00.js';
          }
          script.src = scriptSrc;
          script.onload = () => { 
            setIsResourceLoaded(true);
            resolve();
          };
          document.body.appendChild(script);

          // check for loaded script
          setTimeout(function () {
            if(!isResourceLoaded) reject("Timeout");
          }, process.env.REACT_MONERIS_RESOURCE_TIMEOUT ? Number(process.env.REACT_MONERIS_RESOURCE_TIMEOUT) : 5000);
        }else{
          resolve();
        }
      });
    }

    const getMonerisCheckout = () => {
      if(!monerisRef.current){
      // eslint-disable-next-line no-undef
        monerisRef.current = new monerisCheckout();
      }
      return monerisRef.current;
    }

    const getNewMonerisCheckout = () => {
      // eslint-disable-next-line no-undef
      monerisRef.current = new monerisCheckout();
      return monerisRef.current;
    }

    const cleanupTransaction = () => {
      let monerisCheckout = getMonerisCheckout();
      if(monerisCheckout.transactionTicket){
          try {
              monerisCheckout.closeCheckout(" ");//monerisCheckout.transactionTicket);
              monerisCheckout.transactionTicket = null;
          } catch (error) {
              //console.error(error);
          }
      }
    };
  
    const provider = {
        loadMonerisResources,
        getMonerisCheckout,
        getNewMonerisCheckout,
        partialPaymentAmount,
        setPartialPaymentAmount,
        monerisEnvironment,
        cleanupTransaction
    };

    return (
      <MonerisContext.Provider value={provider}>{children}</MonerisContext.Provider>
    );
  };