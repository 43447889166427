import React, { useState, forwardRef, useEffect, useRef } from "react";
import DisplayField from "./DisplayField";

export default function FOIRequest(props) {
    return (
        <div>
            <div className="row">    
                <label className="col-sm-3 text-sm-end fw-bold">Request Number</label>
                <div className="col text-sm-start mb-2 fw-bold">
                    {props.requestId}
                </div>
            </div>
            <div className="row">    
                <label className="col-sm-3 text-sm-end">Name</label>
                <div className="col text-sm-start mb-2">
                    {props.requesterFirstName} {props.requesterLastName}
                </div>
                { props.requesterCompanyName && <>
                    <label className="col-sm-3 text-sm-end">Company</label>
                    <div className="col text-sm-start mb-2">
                        {props.requesterCompanyName}
                    </div>
                </> }
            </div>
            <div className="row">    
                <label className="col-sm-3 text-sm-end">Address</label>
                <div className="col text-sm-start mb-2">
                    {props.requesterAddress}
                </div>
                <label className="col-sm-3 text-sm-end">Municipality</label>
                <div className="col text-sm-start mb-2">
                    {props.requesterMunicipality}
                </div>
            </div>
            <div className="row">    
                <label className="col-sm-3 text-sm-end">Country</label>
                <div className="col text-sm-start mb-2">
                    {props.requesterCountry}
                </div>
                <label className="col-sm-3 text-sm-end">{props.requesterCountry === 'Canada' ? 'Province' : 'Province / State'}</label>
                <div className="col-sm-3 text-sm-start mb-2">
                    {props.requesterProvince}
                </div>
            </div>
            <div className="row">  
                <label className="col-sm-3 text-sm-end">{props.requesterCountry === 'Canada' ? 'Postal Code' : 'Postal / ZIP Code'}</label>
                <div className="col-sm-3 text-sm-start mb-2">
                    {props.requesterPostalCode}
                </div>  
                <label className="col-sm-3 text-sm-end">Phone Number</label>
                <div className="col-sm-3 text-sm-start mb-2">
                    {props.requesterPhone}
                </div>  
            </div>
            <div className="row">  
                <label className="col-sm-3 text-sm-end">Email</label>
                <div className="col-sm-3 text-sm-start mb-2">
                    {props.requesterEmail}
                </div>  
            </div>

            <div className="row">
                <label className="col-sm-3 text-sm-end">Details</label>
                <div className="col-sm-9 text-sm-start mb-2">
                    <div>{props.description}</div>
                    {props.descriptionDoc && <div>{props.descriptionDoc.map(file => <div key={file.serverId}>({file.filename})</div>)}</div>}
                </div>
            </div>

            { (props.fromDate || props.toDate) && 
            <div className="row">
                <label className="col-sm-3 text-sm-end">Date Range</label>
                <div className="col-sm-3 text-sm-start mb-2">
                    {props.fromDate && new Intl.DateTimeFormat('en-CA').format(props.fromDate)} { props.fromDate && props.toDate && 'to' } {props.toDate && new Intl.DateTimeFormat('en-CA').format(props.toDate)}
                </div>
            </div> }

            <div className="row">
            {props.authorizationLetter && props.authorizationLetter.length === 1 && <>
                    <label className="col-sm-3 text-sm-end">Authorization Letter</label>
                    <div className="col-sm-3 text-sm-start mb-2">
                        {props.authorizationLetter.map(file => <div key={file.serverId}>{file.filename}</div>)}
                    </div>
                    </>
            }  
            {(props.infoRequestLastNameCheckbox || props.infoRequestLastName) && 
                <>
                    <label className="col-sm-3 text-sm-end">Last Name Appearing on Records</label>
                    <div className="col-sm-3 text-sm-start mb-2">
                        {props.infoRequestLastNameCheckbox ? "Same as reqester" : props.infoRequestLastName}
                    </div>  
                </>
                }
                </div>
        </div>
    )
}