export default function Header(props) {
  return (
    <header id="header" className="header">
      <div id="masthead" className="masthead">
        <div className="masthead__primary">
          <img src="/img/logo.svg" alt="Home" className="logo d-print-none" width="284" height="56" />
          <img src="/img/logo--york--print.svg" alt="Home" className="logo d-none d-print-block" width="284" height="56" />
          <h1>Freedom of Information Access / Correction Request</h1>
        </div>
      </div>
    </header>
  );
}