import React from "react";
import Home from "./components/Home";
import { MonerisProvider } from './components/MonerisProvider';
import './App.scss';

export default function App() {
  return (
    <MonerisProvider>
      <Home/>
    </MonerisProvider>
  );
}
